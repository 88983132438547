import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { RoutePaths } from 'core/models';
import { Link } from 'react-router-dom';
import * as S from './styled';

interface NavMenuItemProps {
  id: string,
  path: RoutePaths;
  labelKey: string;
}
const NavMenuItem = ({ id, path, labelKey }: NavMenuItemProps) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Core);
  return (
    <S.UnsetDiv>
      <Link id={id} to={path}>
        { t(labelKey) }
      </Link>
    </S.UnsetDiv>

  );
};
export default NavMenuItem;
