import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18NextChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import auth from 'feature/auth/locales/en.json';
import core from 'core/locales/en.json';
import maintenance from 'feature/maintenance/locales/en.json';
import ICU from 'i18next-icu';
import assets from 'feature/assets/locales/en.json';
import HttpBackend from 'i18next-http-backend';
import companies from 'feature/companies/locales/en.json';
import feedback from 'feature/feedback/locales/en.json';
import dashboard from 'feature/dashboard/locales/en.json';
import roboticmower from 'feature/roboticmower/locale/en.json';
import faq from 'feature/faq/locales/en.json';
import { InternationalizationBackendNameSpace, InternationalizationNameSpace } from '../models/internationalization-namespace';
import { configApp } from './environment-variables';

export const defaultNS = InternationalizationNameSpace.Core;

export const resources = {
  en: {
    [InternationalizationNameSpace.Auth]: auth,
    [InternationalizationNameSpace.Core]: core,
    [InternationalizationNameSpace.Maintenance]: maintenance,
    [InternationalizationNameSpace.Assets]: assets,
    [InternationalizationNameSpace.Companies]: companies,
    [InternationalizationNameSpace.Feedback]: feedback,
    [InternationalizationNameSpace.Dashboard]: dashboard,
    [InternationalizationNameSpace.RoboticMowerCodes]: roboticmower,
    [InternationalizationNameSpace.Faq]: faq,
  },
} as const;

i18next.use(initReactI18next).use(ICU).use(I18NextChainedBackend)
  .init({
    lng: 'en',
    ns: [
      InternationalizationBackendNameSpace.Status,
      InternationalizationNameSpace.Auth,
      InternationalizationNameSpace.Core,
      InternationalizationNameSpace.Maintenance,
      InternationalizationNameSpace.Assets,
      InternationalizationNameSpace.Companies,
      InternationalizationNameSpace.Dashboard,
      InternationalizationNameSpace.RoboticMowerCodes,
      InternationalizationNameSpace.Faq,
    ],
    initImmediate: false,
    backend: {
      crossDomain: true,
      backends: [
        resourcesToBackend(resources),
        HttpBackend,
      ],
      backendOptions: [
        {
          loadPath: '/locales/{{lng}}.json',
        },
        {
          loadPath: `${configApp.translations}/{{ns}}.{{lng}}.json`,
        },
      ],
    },
    defaultNS,
  });
