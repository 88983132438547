import { centeredFlex } from 'core/styles';
import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  ${() => centeredFlex};
`;
export const CallbackWrapper = styled.div`
  width: 100%;
`;

export const ErrorSpan = styled.div`
  color: ${({ theme }) => theme.colors.error500};
  font-size: 14px;
`;
