import { lightTheme } from 'hudskit-framework-react';
import { createGlobalStyle } from 'styled-components';
import { zLayoutNavigation } from './z-index';

export const UptimeCenterGlobalStyle = createGlobalStyle`
* {
  scrollbar-color: ${({ theme }) =>
    `${theme === lightTheme ? theme.colors.gray300 : theme.colors.gray600}
     ${theme === lightTheme ? theme.colors.gray200 : theme.colors.gray800}`};
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb { /* Foreground */
    background: ${({ theme }) => (theme === lightTheme ? theme.colors.gray300 : theme.colors.gray600)};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track { /* Background */
    background: ${({ theme }) => (theme === lightTheme ? theme.colors.gray200 : theme.colors.gray800)};
    border-radius: 10px;
  }
  box-sizing: border-box;
}

body {
  height: auto;
}

header{
  *{
    box-sizing: content-box;
  }
  position: sticky;
  top: 0;
  z-index: ${zLayoutNavigation}
}

:root {
  height: 100vh;
  color: ${({ theme }) => theme.colors.onSurface};
}
`;

export { paddings, borderRadius } from './variables';
