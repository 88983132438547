import styled from 'styled-components';
import { darkTheme, boxShadows } from 'hudskit-framework-react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SendFeedbackDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FeedbackLabel = styled.span`
  color: ${({ theme }) => theme.colors.onSecondary};
  position: relative;
  bottom: 10px;
  right: 50%;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.6;
`;

export const SendEmailAnchor = styled.a`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.onSecondary};
  width: 145px;
  height: 40px;
  border: none;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 14px;
  font-size: 14px;

  &:hover {
    box-shadow: ${({ theme }) => (theme === darkTheme ? boxShadows.dark : boxShadows.light)};
    cursor: pointer;
  };
`;

const GreetingsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const Message = styled(GreetingsDiv)`
  padding: 32px 0;
`;
